import React, { useEffect } from 'react';

// Function to generate UUID v4
function generateUUID() {
  if (crypto.randomUUID) {
    return crypto.randomUUID();
  } else {
    // Fallback for browsers without crypto.randomUUID
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 15) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

function App() {
  useEffect(() => {
    // Function to get or set 'ewf1' cookie
    const getOrSetUUIDCookie = () => {
      const cookieName = 'ewf1';
      const cookies = document.cookie.split('; ').reduce((acc, current) => {
        const [name, value] = current.split('=');
        acc[name] = value;
        return acc;
      }, {});

      let ewf1 = cookies[cookieName];
      if (!ewf1) {
        ewf1 = generateUUID();
        // Set the cookie with expiry of one year
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        document.cookie = `${cookieName}=${ewf1}; expires=${expires.toUTCString()}; path=/; Secure`;
      }
      return ewf1;
    };

    const ewf1 = getOrSetUUIDCookie();

    // Collect client device information
    const deviceInfo = {
      userAgent: navigator.userAgent,
      language: navigator.language,
      platform: navigator.platform,
      url: window.location.href,
      referrer: document.referrer || null,
      ewf1: ewf1,
      // Add more device info as needed
    };

    // Send data to backend API
    fetch('https://api.lunaris.top/track', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(deviceInfo),
      keepalive: true,
    })
    .catch(err => console.error('Error sending device info:', err));
  }, []);

  return (
    <div>
      {/* Blank page for iframe injection */}
    </div>
  );
}

export default App;